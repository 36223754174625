<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col
          cols="2"
          md="2"
          sm="2"
          class="align-self-end"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dates"
                label="Date Range in Table"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              scrollable
              color="primary"
            >
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="refreshRxList"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <div class="tw-pt-1">
            <v-dialog
              v-model="isDialogVisible"
              persistent
              max-width="800px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Advanced Search
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">Advanced Compound Search</span>
                </v-card-title>
                <v-tabs
                  v-model="currentTab"
                  centered
                >
                  <v-tab>Rx Number</v-tab>
                  <v-tab>Patient name</v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentTab">
                  <v-tab-item :key="1">
                    <div class="tw-pt-8">
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col
                              cols="6"
                            >
                              <v-text-field
                                v-model="rxNumber"
                                label="Rx Number"
                                color="primary"
                                dense
                                clearable
                                :autofocus="autofocus"
                                outlined
                                :rules="[integerValidator]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        outlined
                        @click="isDialogVisible = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="success"
                        @click="getMixtureByRx"
                      >
                        Search
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>

                  <v-tab-item :key="2">
                    <div class="tw-pt-8">
                      <compound-patient-search></compound-patient-search>
                      <!-- <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field

                                label="Last Name"
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                label="First Name"
                                dense
                                persistent-hint
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text> -->
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        outlined
                        @click="isDialogVisible = false"
                      >
                        Close
                      </v-btn>
                      <!-- <v-btn
                        color="success"
                        @click="isDialogVisible = false"
                      >
                        Search
                      </v-btn> -->
                    </v-card-actions>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
        <v-col
          cols="12"
          offset-md="2"
          md="4"
        >
          <!-- <v-btn
            large
            color="success"
            @click="debouncedUpdateRx(item)"
          >
            Save <v-icon
              dark
              right
            >
              {{ icons.mdiCheckboxMarkedCircleOutline }}
            </v-icon>
          </v-btn> -->
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <v-btn
            large
            color="primary"
            @click="fetchAllRx()"
          >
            Refresh list<v-icon
              dark
              right
            >
              {{ icons.mdiCheckboxMarkedCircleOutline }}
            </v-icon>
          </v-btn>
        </v-col>

        <!-- <v-btn
            large
            color="success"
            @click="startLoad()"
          >
            startLoad <v-icon
              dark
              right
            >
              {{ icons.mdiCheckboxMarkedCircleOutline }}
            </v-icon>
          </v-btn> -->
        <!-- </v-col> -->
      </v-row>
    </v-card-text>

    <!-- Table https://codepen.io/lzhoucs/pen/aadaJx excellent resource  :group-by="groupby" show-select-->
    <v-data-table
      :key="mixTableKey"
      v-model="selected"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
      :loading="loadingRxToday"
      :headers="headers"
      item-key="RxNum"
      :items="rxList"
      :search="search"
      :items-per-page="30"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      sort-by="RxNum"

      :sort-desc="['true']"
      class="table-kitchen-sink"
      @item-expanded="getMixtureDetails"
    >
      <template
        v-slot:expanded-item="{ headers, item }"
      >
        <td
          colspan="6"
        >
          <v-textarea
            v-model="item.notes"
            class="pt-5"
            label="Notes"
            placeholder="Record any relevant Notes"
            outlined
            @input="debouncedUpdateRx(item)"
          ></v-textarea>
        </td>
        <td
          colspan="6"
        >
          <v-dialog
            v-model="isDialogVisibleIngredients"
            width="800px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                x-large
                color="error"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Ingredient Log
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Compound Ingredient list
              </v-card-title>

              <v-container fluid>
                <v-data-table
                  :headers="mixturDetailsHeaders"
                  :items="mixtureDetails"

                  :items-per-page="15"
                  class="table-kitchen-sink"
                  item-key="DIN"
                >
                  <template
                    #[`item.measured`]="{ item }"
                  >
                    <div class="tw-pt-4">
                      <v-text-field
                        v-model="item.measured"
                        label="measured"
                        placeholder="Type measured"
                        outlined
                      ></v-text-field>
                      <!-- @input="debouncedUpdateRx(item)" -->
                      <!-- @change="updateChipStatus(item)" -->
                    </div>
                  </template>
                </v-data-table>
              </v-container>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="isDialogVisibleIngredients = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </td>
      </template>
      <template
        #[`item.rphQuantity`]="{ item }"
      >
        <FormulateInput
          v-model="item.rphQuantity"
          class="tw-pt-4"
          type="text"
          name="Quantity"
          validation="number"
          error-behavior="live"
        />
      </template>
      <template
        #[`item.mixStatus`]="{ item }"
      >
        <div class="tw-pt-4">
          <v-select
            v-model="item.mixStatus"
            :items="Object.keys(statusList)"
            :prepend-icon="icons.mdiListStatus"
            single-line
            @input="debouncedUpdateRx(item)"
            @change="updateChipStatus(item)"
          ></v-select>
        </div>
      </template>
      <template
        #[`item.initials`]="{ item }"
      >
        <div class="tw-pt-4">
          <v-text-field
            v-model="item.initials"
            label="Initials"
            placeholder="Type initials"
            outlined
            @input="debouncedUpdateRx(item)"
          ></v-text-field>
          <!-- @change="updateChipStatus(item)" -->
        </div>
      </template>

      <template
        v-for="noteheader in headers.filter((noteheader) =>
          noteheader.hasOwnProperty('checker')
        )"
        v-slot:[`item.${noteheader.value}`]="{ header, value }"
      >
        <v-icon color="success">
          {{ `${noteheader.checker(value)}` }}
        </v-icon>
      </template>
      <!-- <template
        #[`item.notes`]="{ item }"
      >
        <div class="tw-pt-4">
          <v-text-field
            v-model="item.notes"
            label="notes"
            placeholder="Placeholder"
            outlined
          ></v-text-field> -->
      <!-- @change="updateChipStatus(item)" -->
      <!-- </div>
      </template> -->
      <template
        #[`item.documentation`]="{ item }"
        :loading="loadingAmend"
      >
        <v-icon
          color="success"
          medium
          class="me-3"
          @click="saveRxInfo(item, item['PATIENT ID'])"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
      <template
        #[`item.speedDial`]="{ item }"
        :loading="loadingAmend"
      >
        <div id="create">
          <v-speed-dial
            v-model="fab"
            direction="right"
            bottom
            left
            open-on-hover
            transition="scale-transition"
          >
            <template
              #activator
              #[`item.speedDial`]="{ item }"
            >
              <v-btn
                v-model="fab"
                color="primary"
                dark
                fab
                @click="saveRxInfo(item, item['PATIENT ID'])"
              >
                <v-icon v-if="fab">
                  {{ icons.mdiClose }}
                </v-icon>
                <v-icon v-else>
                  {{ icons.mdiAccountCircleOutline }}
                </v-icon>
              </v-btn>
            </template>

            <v-btn
              fab
              dark
              small
              color="success"
            >
              <v-icon>{{ icons.mdiPencil }}</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="info"
              @click="saveRxInfo(item, item['PATIENT ID'])"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="error"
              @click="saveRxInfo(item, item['PATIENT ID'])"
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </v-speed-dial>
        </div>
      </template>
      <!-- RxDate -->
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <!-- product -->
      <template #[`item.product.name`]="{item}">
        <div class="d-flex align-center">
          <div>
            <v-img
              :src="item.product.image"
              height="40"
              width="40"
            ></v-img>
          </div>
          <div class="d-flex flex-column ms-3">
            <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.product.name }}</span>
            <span class="text-xs">{{ item.product.brand }}</span>
          </div>
        </div>
      </template>
      <template #[`item.date`]="{item}">
        <span class="text-no-wrap">{{ item.date }}</span>
      </template>
      <!-- category -->
      <template #[`item.category`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            v-for="(category,index) in categoryIconFilter(item.product.category)"
            :key="index"
            size="26"
            :color="category.color"
            :class="`${category.color}--text`"
            class="v-avatar-light-bg"
          >
            <v-icon
              size="20"
              :color="category.color"
              class="rounded-0"
            >
              {{ category.icon }}
            </v-icon>
          </v-avatar>
          <span class="ms-1 text-no-wrap">{{ item.product.category }}</span>
        </div>
      </template>
      <!-- buyer -->
      <template #[`item.buyer.name`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            size="1.875rem"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="item.buyer.avatar"
              :src="item.buyer.avatar"
            ></v-img>
            <span v-else>{{ item.buyer.name.slice(0,2).toUpperCase() }}</span>
          </v-avatar>
          <span class="text-no-wrap font-weight-semibold text--primary ms-2">{{ item.buyer.name }}</span>
        </div>
      </template>
      <!-- payment -->
      <template #[`item.payment`]="{item}">
        <div class="d-flex flex-column">
          <div class="d-flex align-center">
            <span class="primary--text font-weight-medium">${{ item.payment.paid_amount }}</span>
            <span v-if="item.payment.paid_amount !== item.payment.total">/{{ item.payment.total }}</span>
          </div>
          <span class="text-xs text-no-wrap">{{ item.payment.received_payment_status }}</span>
        </div>
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          :hidden="!item.mixStatus"
          small
          class="v-chip-light-bg"
          :color="statusList[item.mixStatus] || ''"
          :class="`${statusList[item.mixStatus]}--text`"
        >
          {{ item.mixStatus }}
        </v-chip>
      </template>
      <!-- <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="statusColor[status[item.status]]"
          :class="`${statusColor[status[item.status]]}--text`"
          class="v-chip-light-bg"
        >
          {{ status[item.mixStatus] }}
        </v-chip>
      </template> -->
      <template #[`item.delete`]="">
        <a><v-icon>{{ icons.mdiDeleteOutline }}</v-icon></a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  mdiAccountCircleOutline,
  mdiApple,
  mdiBagPersonalOutline,
  mdiBluetooth,
  mdiCalendar,
  mdiCellphone,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckCircle,
  mdiClose,
  mdiControllerClassicOutline,
  mdiDeleteOutline,
  mdiDesktopMac,
  mdiFlash,
  mdiGlasses,
  mdiGoogleHome,
  mdiHeadphones,
  mdiHomeOutline,
  mdiIpod,
  mdiKeyboardVariant,
  mdiListStatus,
  mdiMagnify,
  mdiMouse,
  mdiPencil,
  mdiPencilOutline,
  mdiPlus,
  mdiProjector,
  mdiShoeSneaker,
  mdiTapeDrive,
  mdiTelevision,
  mdiVirtualReality,
  mdiWatchVariant,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { integerValidator } from '@core/utils/validation'
import { debounce } from 'lodash'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import compoundPatientSearch from '@/views/patient/components/compoundPatientSearch.vue'
import { momentTimeZone } from '@/util/functions/index'
import { prescription } from '../../render/api/zarya'

export default {
  name: 'CompoundList',
  components: {
    compoundPatientSearch,
  },
  setup() {
    const currentTab = ref(0)
    const isDialogVisibleIngredients = ref(false)
    const search = ref('')
    const fab = ref(false)
    const isDialogVisible = ref(false)

    // const date = new Date().toISOString().substr(0, 10)
    const menu = ref(false)
    const modal = ref(false)
    const menuref = ref(null)

    // const dates = new Date().toISOString().substr(0, 10)

    const date = moment().format('YYYY-MM-DD')
    const dates = moment().format('YYYY-MM-DD')

    // const dates = ref([new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)])
    const categoryIcons = [
      { name: 'Mouse', icon: mdiMouse, color: 'warning' },
      { name: 'Glass', icon: mdiGlasses, color: 'primary' },
      { name: 'Smart Watch', icon: mdiWatchVariant, color: 'success' },
      { name: 'Bag', icon: mdiBagPersonalOutline, color: 'info' },
      { name: 'Storage Device', icon: mdiTapeDrive, color: 'warning' },
      { name: 'Bluetooth', icon: mdiBluetooth, color: 'error' },
      { name: 'Gaming', icon: mdiControllerClassicOutline, color: 'warning' },
      { name: 'Home', icon: mdiHomeOutline, color: 'error' },
      { name: 'VR', icon: mdiVirtualReality, color: 'primary' },
      { name: 'Shoes', icon: mdiShoeSneaker, color: 'success' },
      { name: 'Electronics', icon: mdiFlash, color: 'info' },
      { name: 'Projector', icon: mdiProjector, color: 'warning' },
      { name: 'IPod', icon: mdiIpod, color: 'error' },
      { name: 'Keyboard', icon: mdiKeyboardVariant, color: 'primary' },
      { name: 'Smart Phone', icon: mdiCellphone, color: 'success' },
      { name: 'Smart TV', icon: mdiTelevision, color: 'info' },
      { name: 'Google Home', icon: mdiGoogleHome, color: 'warning' },
      { name: 'Mac', icon: mdiApple, color: 'error' },
      { name: 'Headphone', icon: mdiHeadphones, color: 'primary' },
      { name: 'IMac', icon: mdiDesktopMac, color: 'success' },
      { name: 'IPhone', icon: mdiApple, color: 'warning' },
    ]
    const statusColor = {
      Complete: 'primary',
      Pending: 'success',
      InProgress: 'error',
    }

    const categoryIconFilter = categoryName => {
      const index = categoryIcons.findIndex(category => category.name === categoryName)

      if (index !== -1) {
        return [
          {
            icon: categoryIcons[index].icon,
            color: categoryIcons[index].color,
          },
        ]
      }

      return false
    }

    return {
      fab,
      currentTab,
      isDialogVisible,
      integerValidator,
      isDialogVisibleIngredients,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiCalendar,
        mdiPencilOutline,
        mdiPlus,
        mdiAccountCircleOutline,
        mdiClose,
        mdiPencil,
        mdiListStatus,
        mdiCheckCircle,
        mdiCheckboxMarkedCircleOutline,
      },
      dates,
      search,
      statusColor,
      categoryIconFilter,
      categoryIcons,
      date,
      menu,
      modal,
      menuref,
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
    }
  },
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['User']),
  },
  data() {
    return {
      autofocus: true,
      rxNumber: '',
      mixtureDetails: [],
      mixtureSearchByRx: [],
      statusList: {
        InProgress: 'primary',
        Complete: 'success',
        Pending: 'warning',
        Delayed: 'error',
        Hide: '',
      },
      mixTableKey: 0,
      rxList: [],
      selected: [],
      expanded: [],
      loadingRxToday: true,
      singleExpand: true,

      // dateFormat: 'YYYY-MM-DD HH:mm',
      dateFormat: 'YYYY-MM-DD',
      currentDate: moment().format('YYYY-MM-DD'),
      searchPrescription: '',

      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),

      // fromDate: '',
      // toDate: '',
      from: false,

      // groupby: '',

      groupby: 'PatientName',
      to: false,
      mixturDetailsHeaders: [
        {
          text: 'BrandName',
          value: 'BrandName',
          groupable: false,
          width: '20%',
          align: 'left',
        },

        // {
        //   text: 'GenericName',
        //   value: 'GenericName',
        //   groupable: false,
        //   width: '20%',
        //   align: 'left',
        // },
        {
          text: 'Qty',
          value: 'Qty',
          groupable: false,
          width: '10%',
          align: 'left',
        },
        {
          text: 'measured',
          value: 'measured',
          groupable: false,
          width: '10%',
          align: 'left',
        },
        {
          text: 'AcqCost',
          value: 'AcqCost',
          groupable: false,
          width: '10%',
          align: 'left',
        },
        {
          text: 'DIN',
          value: 'DIN',
          groupable: false,
          width: '10%',
          align: 'left',
        },
      ],
      headers: [
        // {
        //   text: 'Quantity',
        //   value: 'rphQuantity',
        //   groupable: false,
        //   width: '10%',
        //   align: 'center',
        // },

        {
          text: 'Status',
          value: 'mixStatus',
          groupable: false,
          width: '15%',
          align: 'left',
        },
        {
          text: 'Initials',
          value: 'initials',
          groupable: false,
          width: '10%',
          align: 'left',
        },
        {
          text: 'Patient Name',
          align: 'left',
          value: 'PatientName',
          width: '15%',
        },
        {
          text: 'Medication',
          align: 'left',
          value: 'Description',
          width: '25%',
        },

        // {
        //   text: 'Documentation',
        //   value: 'documentation',
        //   align: 'left',
        //   width: '10%',
        //   groupable: false,
        //   sortable: false,
        // },
        // {
        //   text: 'Multi Action',
        //   value: 'speedDial',
        //   align: 'left',
        //   width: '10%',
        //   groupable: false,
        //   sortable: false,
        // },

        { text: 'STATUS', value: 'status', width: '10%' },

        // {
        //   text: 'Dr Last name',
        //   align: 'center',
        //   value: 'DoctorLastName',
        //   width: '10%',
        // },
        {
          text: 'Rx Number',
          align: 'center',
          value: 'RxNum',
          width: '10%',
          groupable: false,
        },
        {
          text: 'Completed Time',
          align: 'center',
          value: 'CompletedTime',
          width: '10%',
          groupable: false,
        },
        {
          text: 'Notes',
          value: 'notes',
          groupable: false,
          width: '5%',
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
      ],
      prescriptions: [],
      pagination: {
        sortBy: 'date',
      },
      rowsPerPage: [25, 50, { text: 'All', value: -1 }],
    }
  },
  created() {
    this.populatePrescriptionsList()

    // this.refreshRxList()
  },
  methods: {
    resetState() {
      this.rxList = []
      this.prescriptions = []
    },
    currentDates() {
      // const current = new Date()
      // const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      const date = new Date().toISOString().substr(0, 10)

      return date
    },
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`

      return dateTime
    },
    currentTime() {
      const current = new Date()
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`

      return time
    },
    async updateLoad(rxData) {
      const token = await this.$auth.getTokenSilently()

      const payload = {
        _id: this.$store.getters.PharmacyProfile._id + rxData.RxNum,
        pharmacyID: this.$store.getters.PharmacyProfile._id,
        userID: this.$store.getters.UserProfile._id,
        FirstName: rxData.FirstName,
        LastName: rxData.LastName,
        PatientName: rxData.PatientName,
        ID: rxData.ID,
        MixID: rxData.MixID,
        DocID: rxData.DocID,
        PatientID: rxData.PatientID,
        Description: rxData.Description,
        OrigRxNum: rxData.OrigRxNum,
        RxNum: rxData.RxNum,
        FillDate: rxData.FillDate,
        DispQty: rxData.DispQty,
        AAC: rxData.AAC,
        Cost: rxData.Cost,
        Markup: rxData.Markup,
        Fee: rxData.Fee,
        MixTime: rxData.MixTime,
        MixFee: rxData.MixFee,
        Status: rxData.Status,
        DocAddressLoc: rxData.DocAddressLoc,
        DoctorFirstName: rxData.DoctorFirstName,
        DoctorLastName: rxData.DoctorLastName,
        mixStatus: rxData.mixStatus,
        initials: rxData.initials,
        notes: rxData.notes,
        Accreditation_Number: this.$store.state.User.pharmacy.accNumber,
        CompletedTime: rxData.CompletedTime,
      }
      await prescription.createRxStatus(token, payload)

      // prescription.createRxStatus(token, payload)

      // console.log('payload', this.prescriptions[0])
      this.isDialogVisible = false
      await this.$root.$emit('Refresh_Log', this.tab)
      this.successSave = true
      window.setTimeout(() => {
        this.successSave = false

        // console.log('hide alert after 3 seconds')
      }, 2000)

      // this.initialState()

      // console.log('initialState', this.initialState())
      // await this.forceRerender()
    },
    debouncedUpdateRx: debounce(function (rxData) {
      this.updateLoad(rxData)
    }, 500),
    async startLoad() {
      const token = await this.$auth.getTokenSilently()
      this.loadingRxToday = true
      const payload = []

      this.prescriptions.forEach(rxData => {
        payload.push({
          _id: this.$store.getters.PharmacyProfile._id + rxData.RxNum,
          pharmacyID: this.$store.getters.PharmacyProfile._id,
          userID: this.$store.getters.UserProfile._id,
          FirstName: rxData.FirstName,
          LastName: rxData.LastName,
          PatientName: rxData.PatientName,
          ID: rxData.ID,
          MixID: rxData.MixID,
          DocID: rxData.DocID,
          PatientID: rxData.PatientID,
          Description: rxData.Description,
          OrigRxNum: rxData.OrigRxNum,
          RxNum: rxData.RxNum,
          FillDate: rxData.FillDate,
          DispQty: rxData.DispQty,
          AAC: rxData.AAC,
          Cost: rxData.Cost,
          Markup: rxData.Markup,
          Fee: rxData.Fee,
          MixTime: rxData.MixTime,
          MixFee: rxData.MixFee,
          Status: rxData.Status,
          DocAddressLoc: rxData.DocAddressLoc,
          DoctorFirstName: rxData.DoctorFirstName,
          DoctorLastName: rxData.DoctorLastName,
          mixStatus: rxData.mixStatus,
          initials: rxData.initials,
          notes: rxData.notes,
          Accreditation_Number: this.$store.state.User.pharmacy.accNumber,
          CompletedTime: rxData.CompletedTime,
        })

        // await prescription.createRxStatus(token, payload)
      })

      await prescription.createRxStatusList(token, payload)

      // prescription.createRxStatus(token, payload)
      await this.fetchAllRx()
      this.mixTableKey += 1
      this.loadingRxToday = false

      // console.log('payload', this.prescriptions[0])
      this.isDialogVisible = false
      await this.$root.$emit('Refresh_Log', this.tab)
      this.successSave = true
      window.setTimeout(() => {
        this.successSave = false

        // console.log('hide alert after 3 seconds')
      }, 2000)

      // this.initialState()

      // console.log('initialState', this.initialState())
      // await this.forceRerender()
    },
    async updateChipStatus(item) {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      console.log(dateTime)
      console.log(item)
      if (item.mixStatus === 'Complete') {
        const token = await this.$auth.getTokenSilently()

        const payload = {
          _id: this.$store.getters.PharmacyProfile._id + item.RxNum,
          pharmacyID: this.$store.getters.PharmacyProfile._id,
          userID: this.$store.getters.UserProfile._id,
          FirstName: item.FirstName,
          LastName: item.LastName,
          PatientName: item.PatientName,
          ID: item.ID,
          MixID: item.MixID,
          DocID: item.DocID,
          PatientID: item.PatientID,
          Description: item.Description,
          OrigRxNum: item.OrigRxNum,
          RxNum: item.RxNum,
          FillDate: item.FillDate,
          DispQty: item.DispQty,
          AAC: item.AAC,
          Cost: item.Cost,
          Markup: item.Markup,
          Fee: item.Fee,
          MixTime: item.MixTime,
          MixFee: item.MixFee,
          Status: item.Status,
          DocAddressLoc: item.DocAddressLoc,
          DoctorFirstName: item.DoctorFirstName,
          DoctorLastName: item.DoctorLastName,
          mixStatus: item.mixStatus,
          initials: item.initials,
          notes: item.notes,
          Accreditation_Number: this.$store.state.User.pharmacy.accNumber,
          CompletedTime: dateTime,
        }

        await prescription.createRxStatus(token, payload)
        await this.fetchAllRx()
        this.mixTableKey += 1
        this.loadingRxToday = false

        // console.log('payload', this.prescriptions[0])
        this.isDialogVisible = false
        await this.$root.$emit('Refresh_Log', this.tab)
        this.successSave = true
        window.setTimeout(() => {
          this.successSave = false

          // console.log('hide alert after 3 seconds')
        }, 2000)
      }

      // FIXME Push item update
      // this.item = {
      //   ...item,
      //   CompletedTime: dateTime,

      //   // status: item.mixStatus,
      // }
      console.log(this.item)
    },
    async fetchAllRx() {
      const accessToken = await this.$auth.getTokenSilently()
      const pharmacyId = this.$store.state.User.pharmacy._id
      const FillDatePost = {
        FillDate: `${this.dates}T00:00:00.000Z`,
        pharmacyID: pharmacyId,
      }

      this.rxList = await prescription.getRxByDate(accessToken, FillDatePost)

      return this.rxList
    },

    //     async fetchAllRx() {
    //   const accessToken = await this.$auth.getTokenSilently()
    //   const pharmacyId = this.$store.state.User.pharmacy._id

    //   // console.log(pharmacyId)
    //   this.rxList = await prescription.getAllRx(accessToken, pharmacyId)
    //   console.log(this.rxList)

    //   return this.rxList
    // },

    async saveRxInfo(rx, patientID) {
      this.$store.dispatch('savePatientData', {
        ...(await this.$root.$system.DBAdapterConn.getPatientData(patientID)),
        selectedMedication: [rx],
      })
      this.$root.$emit('Refresh_Status_Bar', patientID)

      this.$router.push({
        name: 'document-renew-rx',
      })
    },
    async refreshRxList() {
      this.$refs.dialog.save(this.dates)
      this.modal = false

      // this.toDate = this.dates[1]
      this.fromDate = this.dates

      // console.log(`Dates Selected: from: ${this.fromDate}; to: ${this.toDate}`)

      // let fromDateObj = new Date()
      // let toDateObj = new Date()
      // if (this.fromDate && moment(this.fromDate).isValid()) {
      //   fromDateObj = moment(this.fromDate).toDate()
      // }

      // if (this.toDate && moment(this.toDate).isValid()) {
      //   toDateObj = moment(this.toDate).toDate()
      // }

      this.prescriptions = (await this.$root.$system.DBAdapterConn.getMixtures(this.dates, this.dates)) || []
      this.startLoad()
    },
    async populatePrescriptionsList() {
      // console.log(`Dates Selected: from: ${this.fromDate}; to: ${this.toDate}`)

      // let fromDateObj = new Date()
      // let toDateObj = new Date()
      // if (this.fromDate && moment(this.fromDate).isValid()) {
      //   fromDateObj = moment(this.fromDate).toDate()
      // }

      // if (this.toDate && moment(this.toDate).isValid()) {
      //   toDateObj = moment(this.toDate).toDate()
      // }

      this.prescriptions = (await this.$root.$system.DBAdapterConn.getMixtures(this.dates, this.dates)) || []
      this.startLoad()
      this.loadingRxToday = false

      // console.log(this.prescriptions);
    },
    async getMixtureDetails({ item }) {
      this.mixtureDetails = (await this.$root.$system.DBAdapterConn.getMixtureDetails(item.RxNum)) || []
    },
    async getMixtureByRx() {
      this.mixtureSearchByRx = (await this.$root.$system.DBAdapterConn.getMixtureByRx(this.rxNumber)) || []
      this.dates = momentTimeZone(this.mixtureSearchByRx[0].FillDate)
      this.isDialogVisible = false
      this.refreshRxList()
      this.search = this.rxNumber
      this.rxNumber = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.v-btn--fab.v-size--default {
  height: 40px !important;
  width: 40px !important;
}
#create {
  height: 10px;
}
</style>
