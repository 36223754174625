<template>
  <span>
    <v-card>
      <v-form
        ref="form"
        :key="appBarPatientSearchKey"
        v-model="valid"
        class="multi-col-validation"
        @submit.native="updateList"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-text-field
                v-model="patient.lastName"
                label="Last Name"
                outlined
                placeholder="Please enter at least 3 characters"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-text-field
                v-model="patient.firstName"
                label="First Name"
                outlined
                placeholder="Please enter at least 3 characters"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-list
            v-show="!isLoading && list.length"
            rounded
            class="overflow-y-auto"
            max-height="300"
          >
            <v-divider inset></v-divider>
            <v-list-item-content>
              <v-list-item
                v-for="(paitent, index) in list"
                :key="index"
                @click="processPatientInfo(paitent)"
              >
                <v-list-item-avatar>
                  <v-icon
                    medium
                    color="primary"
                    filled
                    dark
                  >
                    folder_open
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ paitent.FirstName }} {{ paitent.LastName }}</v-list-item-title>
                <v-list-item-title>
                  DOB:
                  {{ $moment(paitent.DOB).format("DD-MM-YYYY") }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-content>
          </v-list shaped>
          <v-list v-show="!isLoading && hasSearched && !list.length && msg">
            <v-list-item ripple>
              {{ msg }}
            </v-list-item>
          </v-list>
        </v-container>
        <v-card-actions class="justify-center">
          <v-btn
            large
            color="primary"
            type="submit"
            value="Submit"
            @click="updateList"
          >
            Submit <v-icon
              dark
              right
            >
              {{ icons.mdiTextBoxSearchOutline }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
      <!-- Actions -->

      <!-- Alerts -->
      <v-dialog
        v-model="isLoading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="pt-3">
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="successload"
        hide-overlay
        width="300"
      >
        <v-alert
          type="success"
          class="mb-0"
        >Patient Loaded</v-alert>
      </v-dialog>
    </v-card>
  </span>
</template>

<script>
// import DateTimeLogger from '@components/common/ui/Form/Datepicker/DateTimeLogger'
import {
  alphaDashValidator,
  alphaValidator,
  between,
  confirmedValidator,
  emailValidator,
  integerValidator,
  lengthValidator,
  passwordValidator,
  regexValidator,
  required,
  urlValidator,
} from '@core/utils/validation'
import {
  mdiAccountSearch,
  mdiBackspaceOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiCloudRefresh,
  mdiMinusCircleOutline,
  mdiTextBoxSearchOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Promise } from 'bluebird'
import { debounce } from 'lodash'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AppBarPatientnamesearch',

  components: {
    // datetime: Datetime,
  },
  props: {
    tab: {
      type: Number,
      default: 6,
    },
  },
  setup() {
    // validate
    const requiredField = ref('')
    const numberBetween10to20 = ref('')
    const onlyConsistNumber = ref('')
    const matchRegularEx = ref('')
    const onlyAlphabeticCharacters = ref('')
    const specifiedLength = ref('')
    const password = ref('')
    const digits = ref('')
    const repeatePassword = ref('')
    const onlyAlphabeticNumbersDashesUnderscores = ref('')
    const email = ref('')
    const validURL = ref('')
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    //

    const isDialogVisible = ref(false)

    return {
      isDialogVisible,
      icons: {
        mdiCloudRefresh,
        mdiMinusCircleOutline,
        mdiCheckboxMarkedCircleOutline,
        mdiAccountSearch,
        mdiBackspaceOutline,
        mdiTextBoxSearchOutline,
      },

      // validation
      requiredField,
      numberBetween10to20,
      onlyConsistNumber,
      matchRegularEx,
      onlyAlphabeticCharacters,
      specifiedLength,
      password,
      digits,
      repeatePassword,
      onlyAlphabeticNumbersDashesUnderscores,
      email,
      validURL,
      validate,
      form,
      emailValidator,
      passwordValidator,
      between,
      integerValidator,
      regexValidator,
      alphaValidator,
      urlValidator,
      confirmedValidator,
      lengthValidator,
      required,
      alphaDashValidator,
    }
  },
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['User']),
    keymap() {
      return {
        // 'esc+ctrl' is OK.
        f1: this.toggle,
        enter: {
          keydown: this.hide,
          keyup: this.show,
        },
      }
    },
  },
  data() {
    return {
      show: true,
      appBarPatientSearchKey: 0,
      userProfile: this.$store.getters.UserProfile,
      loadProfile: false,
      isDialogVisible: false,
      successload: false,
      progress: 0,
      list: [],
      search: {},
      msg: '',
      hasSearched: false,
      isLoading: false,
      patient: {
        firstName: '',
        lastName: '',
        dob: '',
      },
      valid: false,
      user: this.$auth.user.name,
    }
  },
  mounted() {
    this.initialState()
  },
  methods: {
    toggle() {
      this.show = !this.show
      this.isDialogVisible = !this.isDialogVisible
    },

    // show() {
    //   this.show = true
    // },
    hide() {
      this.show = false
    },
    clearFieds() {
      this.patient.firstName = ''
      this.patient.lastName = ''
    },
    updateList(e) {
      e.preventDefault()
      this.hasSearched = false
      this.msg = ''
      this.list = [] // always reset the list
      if (this.patient.firstName.length < 2 && this.patient.lastName.length < 2) {
        return
      }
      this.debounceFetchPatientsSearch()

      return false
    },
    debounceFetchPatientsSearch() {
      // already initialized ? then use it
      if (this.debounceFunc) {
        this.debounceFunc()
      } else {
        // initialize it
        this.debounceFunc = debounce(() => {
          this.isLoading = true

          // console.log('dbAdapterConn', this.$root.$system.DBAdapterConn)
          this.$root.$system.DBAdapterConn.getSearchPatientsByName({
            firstName: this.patient.firstName,
            lastName: this.patient.lastName,
          }).then(
            results => {
              this.isLoading = false
              if (results.length) {
                this.list = results
              } else {
                this.hasSearched = true
                this.msg = 'No data found'
              }
            },

            // (err)=>{
            //   this.msg = 'Error: please try again.';
            // }
          )
        }, 200)
        this.debounceFunc()
      }
    },
    processPatientInfo(patient) {
      this.isDialogVisible = false
      this.isLoading = true
      this.loadProfile = true
      this.$emit('search-patient')
      Promise.delay(20)
        .then(() => {
          this.progress = 75
          this.$store.dispatch('resetPatientData')
          this.resetState()

          // do the request
          return this.$root.$system.DBAdapterConn.getPatientData(patient.PatientID)
        })
        .then(async patientData => {
          this.progress = 100
          await this.$store.dispatch('savePatientData', patientData)
          this.isLoading = false

          return patientData.PatientID
        })
        .then(PatientID => {
          // this.$router.push({
          //   name: 'patient-profile-view',
          // })
          this.routePatient(PatientID)
        })
    },
    routePatient(PatientID) {
      return Promise.delay(20)
        .then(() => {
          this.progress = 100
        })
        .delay(20)
        .then(() => {
          this.resetState()
          this.$root.$emit('Refresh_Table', PatientID)
          this.$root.$emit('Refresh_Medscheck', PatientID)

          // this.$root.$emit('setPatientProfileTab', 6)

          // console.log(this.$root.$emit('Refresh_Table', PatientID))
          this.$emit('selected-patient', PatientID)
          this.isDialogVisible = false
          this.successload = true
          window.setTimeout(() => {
            this.successload = false

            // console.log('hide alert after 3 seconds')
          }, 1000)
          this.forceRerender()

          this.$router.push({
            name: 'patient-profile',
            query: {
              // patient: this.$route.query.options?.patient || false,
              patientID: PatientID,

              // c: Math.random(),
              tab: this.tab,
            },
          })

          // this.$root.$emit('setPatientProfileTab', 6)

          // this.$router.push({
          //   name: this.$route.query.next,
          //   query: {
          //     patient: this.$route.query.options?.patient || false,
          //     patientID: PatientID,
          //     c: Math.random()
          //   }
          // }).catch(err => {
          //   // Ignore the vuex err regarding  navigating to the page they are already on.
          //   if (
          //     err.name !== 'NavigationDuplicated' &&
          //     !err.message.includes('Avoided redundant navigation to current location')
          //   ) {
          //     // But print any other errors to the console
          //     console.error(err);
          //   }
          // });
        })
    },
    newPatient() {
      this.$store.dispatch('resetPatientData')
      this.resetState()
      location.reload()
    },
    resetState() {
      this.progress = 0
      this.loadProfile = false
      this.list = []
      this.patient.firstName = ''
      this.patient.lastName = ''
      this.medicationList = ''
      this.patientData = ''
      this.patientProfile = ''
    },
    forceRerender() {
      this.appBarPatientSearchKey += 1
    },
    initialState() {
      this.isDialogVisible = false
      this.list = []
      this.patient.firstName = ''
      this.patient.lastName = ''
    },
  },
}
</script>

<style scoped>
.v-btn--fab.v-size--default {
  height: 40px;
  width: 40px;
  margin-left: 16px;
}
</style>
